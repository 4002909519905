import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { COUNTRY_OPTIONS } from "../../app/routes/common/worldCountries";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  CircularProgress,
  Logo,
  Row,
  Col,
  ApiUrl,
  SELECT,
  PHONECODES,
} from "../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import { Container } from "@material-ui/core";
import WhyOurBrand from "../../components/auth/authBody/WhyOurBrand/Index";
import HowWeWork from "../../components/auth/authBody/HowWeWork/Index";
import OurClient from "../../components/auth/authBody/OurClient/Index";
import GetStartedSteps from "../../components/auth/authBody/GetStartedSteps/Index";
import ImgWithText from "../../components/auth/authBody/ImageWithText/Index";
import Faq from "../../components/auth/authBody/Faq/Index";
import Header from "../../components/auth/authBody/Header";
import Footer from "../../components/auth/authBody/Footer/Index";
import { SearchSelectInput } from "../../app/routes/common/inputFields";
import { SocketContext } from "../socket";
import { FirebaseContext } from "../../firebase/firebase";

const { API_LOGIN } = ApiUrl;

// const COLORS = "#f15931";
const COLORS = "#4A863E";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.Component {
  static contextType = SocketContext;

  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      countryCode: "🇺🇸 +1",
      mobileNumber: "",
      value: 0,
      disabled: false,
      socketConnected: false,
    };
  }

  handleChange1 = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });
    this.props.showAuthLoader();
    const { email, password, mobileNumber, countryCode } = this.state;

    const formData = {
      email: email.trim(),
      password: password.trim(),
      firebaseToken: this.props?.firebaseValue?.token,
      mobileNumber: mobileNumber.trim(),
      countryCode: countryCode.replace(
        /[\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF]\s*/,
        ""
      ),
    };

    Axios.post(API_LOGIN, formData)
      .then((response) => {
        let data = response.data;

        if (data.status == "failure") {
          toast.error(data.message, {
            toastId: "customId",
          });
        } else {
          data = data.data;

          localStorage.setItem("token", data.token);
          localStorage.setItem("_id", data._id);
          localStorage.setItem("isTopRated", data.isTopRated);
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.role);
          localStorage.setItem("user_id", data.token);
          localStorage.setItem("restaurantStatus", data.restaurantStatus);
          localStorage.setItem("restaurantType", data.restaurantType);

          this.props.userSignInSuccess(data.token);

          if (this.props.socketValue && this.props.socketValue.socket) {
            this.props.socketValue.socket.emit("restaurantsocket", {
              restaurantId: data._id,
            });
          }

          toast.success("Login successfully.");
          setTimeout(() => {
            this.props.history.push("/app/dashboard/index");
          }, 1000);
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error("server error, Please try after some time.", {
            toastId: "customId",
          });
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      });
  };

  reset = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  render() {
    console.log(this.props, "props");
    const {
      email,
      password,
      loading,
      countryCode,
      // errors,
      value,
      mobileNumber,
      disabled,
    } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Grid component="main">
          <Header />
          <section
            container
            className="py-5 MainAccountForm position-relative d-flex align-items-center justify-content-center"
            style={{
              backgroundImage: 'url("../images/accountbg1.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "100vh",
              // zIndex: "9",
            }}
          >
            <Container fixed>
              <Grid container>
                <Grid
                  item
                  md={4}
                  xs={12}
                  container
                  justify="center"
                  spacing={0}
                  direction="column"
                  alignItems="center"
                >
                  {/* <img
                    src={require("assets/images/logo1.png")}
                    className="logo-login"
                  /> */}
                  <div className="bannerContent">
                    <h2 className="text-white m-0 pb-3">
                      Unlock a <span className="theme-clr">new revenue </span>
                      stream
                    </h2>
                    <p className="text-white">
                      tucsoneats global platform gives you the flexibility,
                      visibility and customer insights you need to connect with
                      more customers. Partner with us today.
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={12}
                  // component={Paper}
                  elevation={6}
                  className="ml-auto"
                  square
                  alignContent="center"
                  // style={{ maxHeight: "100vh", overflow: "scroll" }}
                >
                  <div className="accountForm bg-white">
                    {/* <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography
                      className="form-head"
                      component="h1"
                      variant="h5"
                    >
                      Sign in
                    </Typography>

                    <Row>
                      <Col
                        sm="12"
                        //  md={{ offset: 2, size: 8 }}
                      >
                        <form
                          className={classes.form}
                          noValidate
                          onSubmit={(e) => this.loginUser(e)}
                        >
                          <Tabs
                            value={value}
                            onChange={this.handleChange1}
                            TabIndicatorProps={{
                              style: { background: COLORS, height: "4px" },
                            }}
                            textColor="black"
                            variant="fullWidth"
                            centered
                            className="dumpster-tabs mb-4"
                          >
                            <Tab
                              label="Phone"
                              style={{ color: "black", fontWeight: 600 }}
                            />

                            <Tab
                              label="Email"
                              style={{ color: "black", fontWeight: 600 }}
                            />
                          </Tabs>
                          {value == 1 ? (
                            <TEXTFIELD
                              label="Enter Email"
                              fullWidth
                              name="email"
                              onChange={this.handleChange}
                              value={email}
                              errors={{}}
                              variant="outlined"
                            />
                          ) : (
                            <>
                              <label className="form-label">
                                Enter Phone no.
                              </label>
                              <div className="phoneInput d-flex">
                                <div className="">
                                  <SearchSelectInput
                                    label=""
                                    value={countryCode}
                                    name={"countryCode"}
                                    onChange={this.handleChange}
                                    data={COUNTRY_OPTIONS}
                                    // errors={errors}
                                  />
                                </div>
                                <TEXTFIELD
                                  // label="Enter Phone no."
                                  fullWidth
                                  type="number"
                                  name="mobileNumber"
                                  onChange={this.handleChange}
                                  value={mobileNumber}
                                  errors={{}}
                                  // variant="outlined"
                                />
                              </div>
                            </>
                          )}
                               <Col lg={12} md={12} sm={12} className="px-2 mt-4">
                               <Label for="password">Password</Label>
                          <PASSWORDFIELD
                            label=""
                            fullWidth
                            name="password"
                            onChange={this.handleChange}
                            value={password}
                            errors={{}}
                            variant="outlined"
                          />
                          </Col>
                          <Grid container>
                            <Grid item xs className="text-right my-2">
                              <Link
                                href="#/forgot-password"
                                className="form-link"
                                variant="body2"
                              >
                                Forgot password?
                              </Link>
                            </Grid>
                          </Grid>
                          {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    /> */}
                          <div className="py-2">
                            <SUBMIT
                              title="Sign In"
                              className="py-2 "
                              disabled={disabled}
                            />
                          </div>
                          <Grid container className="text-center">
                            <Grid md={12} item className="">
                              <p className="m-0 ">
                                Don't have an account?
                                <Link
                                  href="#/register"
                                  className="form-link"
                                  variant="body2"
                                >
                                  {" Sign Up"}
                                </Link>
                              </p>
                            </Grid>
                          </Grid>
                        </form>
                      </Col>
                    </Row>
                    <ToastContainer />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>
          <WhyOurBrand />
          <ImgWithText />
          <HowWeWork />
          <OurClient />
          <GetStartedSteps />
          <Faq />
          {/* <Footer /> */}
          <Footer />
          <CssBaseline />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

const withContexts = (WrappedComponent) => {
  return (props) => (
    <SocketContext.Consumer>
      {(socketValue) => (
        <FirebaseContext.Consumer>
          {(firebaseValue) => (
            <WrappedComponent
              {...props}
              socketValue={socketValue}
              firebaseValue={firebaseValue}
            />
          )}
        </FirebaseContext.Consumer>
      )}
    </SocketContext.Consumer>
  );
};

export default withContexts(
  connect(mapStateToProps, {
    hideMessage,
    showAuthLoader,
    userSignInSuccess,
    hideAuthLoader,
  })(withStyles(useStyles)(SignIn))
);
